import { TPageText } from './base'

const text: TPageText = {
  name: {
    en: 'App',
    local: 'App',
  },
}

export default text
