import { TPageText } from './base'

const text: TPageText = {
  title: {
    en: 'How it works',
  },
  'header-how-it-works': {
    en: 'How it works',
  },
  'content-how-it-works': {
    en: '',
  },
}

export default text
